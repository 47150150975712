import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0068 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam('/column/details/C0002/')
  const routeParam2 = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam3 = useRouteParam('/column/details/C0067/')
  const routeParam4 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理システムを導入していない会社で発生するリスクとは？" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2023.11.02</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0068.jpg"
          alt="キンクラコラム画像68"
        />
        <p>
          労働環境の変化や法的要件の厳格化に伴い、
          <span>
            勤怠管理システムの導入は企業にとってますます重要性が増えています
          </span>
          。しかし、すべての会社が導入しているわけではなく、検討もできていない企業があることも事実です。そこで本記事では勤怠管理システムを導入しないことによるリスクやデメリット、導入に際して考慮すべきポイントについて、詳しく解説していきます。
        </p>
        <a href={routeParam} className="column-detail-link">
          参照記事：勤怠管理とは？管理をする必要性や管理体制の整備について解説
        </a>
        <h1 id="toc1">
          勤怠管理システムを導入していない会社のリスクやデメリットとは？
        </h1>
        <p>
          近年、労働環境の変化や法的要件の厳格化に伴い、勤怠管理システムの導入が企業にとってますます重要となっています。しかし、まだ導入していない会社もあります。
          <br />
          本章では、勤怠管理システムを導入していない会社が直面するリスクやデメリットについて解説していきます。
        </p>
        <h2 id="toc1-1"> 不正確な記録</h2>
        <p>
          勤怠管理システムを導入していない会社では、従業員の出勤・退勤時間を手作業で記録することが一般的です。しかし、
          <span>手作業で記録する方法はミスが発生しやすい</span>
          という大きな問題があります。ある中小企業の労務担当者であるAさんの体験談をご紹介しましょう。
          <br />
          <br />
          Aさんは毎日、従業員全員の出勤・退勤時間をExcel(エクセル)に手入力していました。ある日、一部の従業員から給与計算に誤りがあると指摘され、調査の結果、Aさんがタイムカードの時間をExcelに入力する際にミスをしていたことが判明。Excelへの入力ミスにより、給与計算が誤って行われ、
          <span>会社側が余分な給与を支払う事態となりました。</span>
        </p>
        <h2 id="toc1-2"> 従業員へ負担がかかる</h2>
        <p>
          手作業での勤怠管理は従業員にも負担をかけます。たとえば、よくある従業員の作業方法に、毎日の出勤・退勤時間をメモに書き留め、月末にそれをExcelにまとめて提出する方法があります。Excelにまとめる作業は思った以上に時間がかかり、本来の業務時間を削ってしまうこともありました。Aさん自身が記録したデータをもとに人事部が給与計算を行うため、Aさんの記録をもとにした
          <span>集計作業にも時間とコストがかかる</span>
          という悪循環もありました。{' '}
        </p>
        <h2 id="toc1-3"> 社会的信頼性が低下する</h2>
        <p>
          勤怠管理を適切に行っていない会社は、
          <span>社会的な信頼性も低下します</span>
          。たとえば、想定されるリスクの一つに、労働者から残業代未払いの訴えがあります。万が一、裁判に発展した場合、企業側は裁判所に適切な勤怠データの提出が必要です。手作業で管理していた勤怠表は、正確なデータであるという根拠がありません。正確性が担保できない勤怠データでは、結果的に労働者側の主張が認められることになるでしょう。適切な勤怠管理を行っていない会社は、社会的な信頼性だけでなく、
          <span>企業の ブランドイメージも低下</span>する事態を招きます。
          <br />
          <br />
          このように、勤怠管理システムを導入していない場合、さまざまなリスクやデメリットが発生します。
        </p>
        <h1 id="toc2"> 勤怠管理システムを導入することによるメリット</h1>
        <img
          src="/images/column/details/c0068_g01.jpg"
          alt="キンクラコラム画像68"
        />
        <p>
          勤怠管理システムの必要性について、理解が深まったところで、勤怠管理システムを導入することで得られる具体的なメリットについて解説しましょう。
        </p>
        <a href={routeParam2} className="column-detail-link">
          参照記事：客先常駐に特化した勤怠管理システム「キンクラ」とは？
        </a>
        <h2 id="toc2-1">入力ミスの軽減</h2>
        <p>
          勤怠管理システムを導入することで、大幅な人為的ミスの減少を期待できます。勤怠管理システムは自動的に記録した出勤・退勤時間をもとに給与計算を行います。
          <span>自動的に給与計算</span>
          ができるため、手作業での記録や計算ミスの減少につながるでしょう。また、万が一入力ミスがあった場合でも、システム上で簡単に修正作業ができます。システム上での修正作業になるため、手作業での修正作業と比べ、時間も労力も大幅に削減できます。
        </p>
        <h2 id="toc2-2">従業員の負担が減る</h2>
        <p>
          勤怠管理システムの導入により、従業員の負担も大幅に軽減されます。従業員は自分の出勤・退勤時間を手作業で記録する必要がなくなり、勤怠管理に充てていた時間を本来の業務に活用できます。また、
          <span>各従業員の勤怠記録を一元管理</span>
          することが可能となり、管理者の作業負担も軽減されるでしょう。勤怠システム導入により従業員の負担が軽減され、人為的ミスの削減にもつながるため、給与計算や労働時間管理などの人事業務の効率化にもつながります。
        </p>
        <h2 id="toc2-3">データを適正に保管できる</h2>
        <p>
          勤怠管理システムでは、出勤・退勤時間などの勤怠データをデジタル化で保存します。デジタル化された勤怠データは「客観的な記録」に当たるため、法的な問題が生じた際の証拠としても有効です。また、データはクラウド上に保存されるため、紙ベースの記録とは異なり、火災や水害などの災害からも守られます。
        </p>
        <h2 id="toc2-4">デバイスに依存しない</h2>
        <p>
          最近ではクラウド型の勤怠管理システムが主流となっています。クラウド型のシステムはインターネットに接続された任意のデバイスからアクセス可能であり、場所や時間を問わずに勤怠入力が可能です。どこからでもアクセスできることにより、リモートワークや出張など、
          <span>多様な労働環境に対応</span>できます。
        </p>
        <a href={routeParam3} className="column-detail-link">
          参照記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <p>
          <br />
          以上のように、勤怠管理システムを導入することでさまざまなメリットを享受できます。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <h1 id="toc3">
          ミスマッチを減らす、勤怠管理システムを導入する際のポイント
        </h1>
        <p>
          勤怠管理システムを導入することの必要性やメリットについて理解ができてきたところで、実際に導入する際のポイントについてご紹介します。
        </p>
        <h2 id="toc3-1">自社の勤務体系に合っているか？</h2>
        <p>
          勤怠管理システムを導入する際には、自社の勤務体系にシステムが対応しているかを確認しておきましょう。たとえば、シフト制やフレックスタイム制など、企業ごとに勤務体系はさまざまです。企業により勤務形態は異なるため、
          <span>自社の勤務体系に合ったシステムを選ぶ</span>
          ことで、より効率的な勤怠管理が可能となります。
        </p>
        <h2 id="toc3-2">導入コスト</h2>
        <p>
          導入コストについても考慮しなければならないポイントです。システムの導入には初期費用だけでなく、運用・保守費用も発生します。システム導入後のランニングコストも想定した予算を策定し、運用できるシステムを選ぶことが重要です。従業員の人数に合わせた従量課金や、サブスクイプションのように毎月定額で設定されているものもあります。また、コストパフォーマンスも考慮に入れましょう。コストパフォーマンスを考慮することで、投資したコストに対してどれだけの効果が得られるか、
          <span>実務上の業務効率化を評価</span>できます。
        </p>
        <h2 id="toc3-3">トライアル期間</h2>
        <p>
          トライアル期間の有無も確認しましょう。多くの勤怠管理システムでは、一定期間無料でシステムを試用できるトライアル期間が設定されています。トライアル期間を利用して
          <span>
            システムの使い勝手や機能を確認し、自社に合ったシステムかどうかを判断
          </span>
          しましょう。
          <br />
          <br />
          以上のポイントを意識することで、勤怠管理システムを導入後にありがちな、自社に合わず導入費用とランニングコストがかかってしまったというリスクを軽減することが可能になります。
        </p>
        <h1 id="toc4">勤怠管理システムなら「キンクラ」がおすすめです </h1>
        <p>
          勤怠管理システムの導入には多くのリスクやデメリットが存在し、手作業による不正確な記録や従業員への負担、社会的信頼性の低下などが挙げられます。しかし、勤怠管理システムを導入することにより、
          <span>
            入力ミスの軽減、従業員の負担軽減、データの適切な保管、デバイスに依存しない柔軟な利用が可能となります。
          </span>
          <br />
          <br />
          とはいえ、勤怠管理システムはさまざまな種類が存在し、どれを選定すればよいか迷われている担当者も多いでしょう。
          <br />
          特にSESといった客先常駐型の企業様では、勤務形態が従業員によって変わるため、それに合った勤怠管理システムを導入したいと考えているかと思います。
          <br />
          <br />
          もし、もしそのような業界で勤怠管理システムの導入を検討しているならば、
          <span>客先常駐に特化した「キンクラ」をお勧めしています。</span>
          <br />
          キンクラでは、全機能が無料で使える「無料トライアル」を実施していますので、勤怠管理システム導入を検討しているならば「キンクラ」をお試ししてみることをお勧めしています。
        </p>
        {/* TODO 石山：CTA内容が決まり次第差し替え */}
        <a href={routeParam4} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0068
