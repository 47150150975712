import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 60px;
  border: solid 2px #e5e5e5;
  border-radius: 5px;
  padding: 20px;

  section {
    display: flex;
    justify-content: left;
    align-items: center;

    img {
      width: 110px;
      height: 110px;
      margin: 0 20px 0 0;
    }

    .author-wrap {
      position: relative;
      width: 100%;

      p {
        line-height: 2;

        &.author-name {
          font-size: 18px;
          font-weight: 600;

          span {
            font-weight: 400;
            font-size: 14px;
          }
        }

        &.author-job {
          position: relative;
          width: 100%;
          margin-left: 14px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 10px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }

          a {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .detail-txt {
    border-top: solid 1px #dcdee1;
    margin-top: 20px;
    padding-top: 15px;
    line-height: 1.8;
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：オーサ情報 辰本 実香（タツモト ミカ）
 * @constructor
 */
const columnAuthor004 = () => (
  <Wrapper>
    {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
    <section>
      <img src="/images/column/authors/a004.png" alt="辰本　実香" />
      <div className="author-wrap">
        <p className="author-name">
          辰本 実香<span>（タツモト ミカ）</span>
        </p>
        <p className="author-job">株式会社Efficiency M 代表取締役</p>
      </div>
    </section>
    <div className="detail-txt">
      税理士法人での勤務経験を活かし、バックオフィス関連の業務効率化を提案する傍ら、経営者が抱える実務上の問題解決をサポートしている。
      <br />
      また、業務効率化にまつわるDX化を中心に、バックオフィス業務に関連する幅広い分野の執筆活動も行う。
    </div>
  </Wrapper>
)

export default columnAuthor004
